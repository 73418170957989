const featureCards = [
  {
    id: 1,
    title: 'Identity Regeneration',
    description: `Regenerate your identity in one-tap.
      All previous message history and any trace of your account is wiped 
      from your application and from the server.`,
    icon: require('../images/feature-1-icon.svg').default,
    img: require('../images/feature-1.svg').default
  },
  {
    id: 2,
    title: 'Burn Signal',
    description: `Share your personal "Burn Code" with your close connects which can be used to remotely wipe all of your data from 
    your application and from the server.`,
    icon: require('../images/feature-2-icon.svg').default,
    img: require('../images/feature-2.svg').default

  },
  {
    id: 3,
    title: 'One-click Wipe',
    description: `Wipe your device with an easy access wipe button that when pressed will remove all data from your application and from the server.`,
    icon:require('../images/feature-3-icon.svg').default,
    img: require('../images/feature-3.svg').default


  },
  {
    id: 4,
    title: 'Burn Password',
    description: `Use a custom "Burn Password" when unlocking the application, which when entered, will wipe all of your data from the application and from the server.`,
    icon: require('../images/feature-4-icon.svg').default,
    img: require('../images/feature-4.svg').default


  },
  {
    id: 5,
    title: 'Deadman Switch',
    description: `Set a custom activity timer or "Deadman Switch" to wipe your data from your application and from the server if you 
    do not unlock the application for a set period of time. `,
    icon: require('../images/feature-5-icon.svg').default,
    img: require('../images/feature-5.svg').default

  },
];

export default featureCards;
