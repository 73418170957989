import React, { useEffect, useState } from 'react';

import Header from '../partials/Header';
import BlogList from '../partials/BlogList';
import Newsletter from '../partials/Newsletter';
import Footer from '../partials/Footer';

function Blog() {
  const [blogPaths, setBlogPaths] = useState([]);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {        
      fetch('/blogs/blogs.json')
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not OK');
          }
          return response.json();
        })
        .then((res) => {
          setBlogPaths(res);
        });
  }, []);

  useEffect(() => {      
        const fetchMetadata = (blogPath) => {
          fetch(`${blogPath}/metadata.json`)
            .then((response) => {
              if (!response.ok) {
                throw new Error('Network response was not OK');
              }
              return response.json();
            })
            .then((res) => {
              setBlogs((old) => {
                return old.concat({
                  ...res,
                  blogPath,
                });
              });
            });
        };

        if (blogPaths.length > 0) {
          setBlogs([]);
          
          blogPaths.forEach((blogPath) => {
            fetchMetadata(blogPath);
          });
        }
  }, [blogPaths]);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page sections */}
        <BlogList blogs={blogs} />
        <Newsletter />
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Blog;
