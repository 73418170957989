import React, { useState, useEffect } from 'react';
import Header from '../partials/Header';
import BlogSingle from '../partials/BlogSingle';
import Footer from '../partials/Footer';
import useQuery from '../hooks/useQuery';

function BlogPost() {
  const [blog, setBlog] = useState({});
  const activePath = useQuery().get('path');

  useEffect(() => {
    fetch(`${activePath}/metadata.json`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not OK');
        }
        return response.json();
      })
      .then((res) => {
        setBlog({
          ...res,
          activePath,
        });
      })
      .catch((err) => console.error(err));
  }, [activePath]);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page sections */}
        <BlogSingle blog={blog} />
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default BlogPost;
